<template>
	<header class="header" :class="{ 'fixed_header': scrolled }">
		<div class="container">
			<div class="row">
				<nav class="navbar navbar-expand-lg" aria-label="Eleventh navbar example">
          <router-link class="navbar-brand" to="/"><img src="/assets/media/images/logo.svg" alt="" /></router-link>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
						aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse  justify-content-end" id="navbarsExample09">
						<ul class="navbar-nav">
							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
									data-bs-toggle="dropdown" aria-expanded="false">Company</a>
								<ul class="dropdown-menu" aria-labelledby="navba  rDropdown">
									<li><router-link to="/aboutus"><img src="/assets/media/images/icone/aboutus.svg" alt="">About Us</router-link>
									</li>
									<li><router-link to="/amb-group"><img src="/assets/media/images/icone/group.svg" alt="">Group Companies</router-link>
									</li>
									<li><router-link to="/management"><img src="/assets/media/images/icone/management.svg" alt="">Our
											Management</router-link></li>
								</ul>

							</li>
							<li class="nav-item dropdown uber_menu">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
									data-bs-toggle="dropdown" aria-expanded="false">Services</a>
								<!-- Dropdown menu -->
								<div class="dropdown-menu uber_menu w-75 mt-0" aria-labelledby="navbarDropdown">
									<div class="container">
										<div class="row">
											<div v-for="(sort,index) in menuItem.service" v-bind:key="index" class="col-md-6 col-lg-4 mb-3 mb-lg-0 d-flex justify-content-start">
												<div  class="list-group list-group-flush">
												<template v-for="item in sort" v-bind:key="item.id">
													<router-link :to="'/services/'+item.slug"><img :src="item.icon_path" alt="">{{ item.name }}</router-link>
												</template>
												</div>
											</div>
										</div>
									</div>
								</div>

							</li>
							<li class="nav-item dropdown uber_menu">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
									data-bs-toggle="dropdown" aria-expanded="false">Technology</a>

								<!-- Dropdown menu -->
								<div class="dropdown-menu uber_menu w-50 mt-0" aria-labelledby="navbarDropdown">
									<div class="container">
										<div class="row">
											<div v-for="(sort,index) in menuItem.technology" v-bind:key="index" class="col-md-6 col-lg-4 mb-3 mb-lg-0 d-flex justify-content-start">
												<div  class="list-group list-group-flush">
													<template v-for="item in sort" v-bind:key="item.id">
														<router-link :to="'/technologies/'+item.slug"><img :src="item.icon_path" alt="">{{ item.title }}</router-link>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>

							</li>
							<li class="nav-item dropdown uber_menu">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
									data-bs-toggle="dropdown" aria-expanded="false">Solutions</a>

								<!-- Dropdown menu -->
								<div class="dropdown-menu uber_menu w-50 mt-0" aria-labelledby="navbarDropdown">
									<div class="container">
										<div class="row">
											<div v-for="(sort,index) in menuItem.solution" v-bind:key="index" class="col-md-6 col-lg-4 mb-3 mb-lg-0 d-flex justify-content-start">
												<div  class="list-group list-group-flush">
													<template v-for="item in sort" v-bind:key="item.id">
													<router-link :to="'/solutions/'+item.slug"><img :src="item.icon_path" alt="">{{ item.title }}</router-link>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>

							</li>
							<li class="nav-item"><router-link class="nav-link" to="/hire-developer">Hire Developer</router-link>
							</li>

							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="javascript:void()" id="navbarDropdown" role="button"
									data-bs-toggle="dropdown" aria-expanded="false">Portfolio</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
								<li><router-link to="/portfolio/local"><img src="/assets/media/images/icone/isign.svg" alt="">Local Clientele</router-link>
								</li>
								<li><router-link to="/portfolio/international"><img src="/assets/media/images/icone/users.svg" alt="">International
										Clientele</router-link></li>
							</ul>
						</li>
						<li class="nav-item"><router-link class="nav-link active hvr-sweep-to-right gray" to="/contact-us">Contact
								Us</router-link></li>
					</ul>
				</div>
			</nav>
		</div>
	</div>
</header>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: ['menuItem'],
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        document.querySelector('.navbar-collapse.collapse').classList.remove('show');
      }
    }
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function(){
      if (window.innerWidth > 992) {
        document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem){
          everyitem.addEventListener('mouseover', function(e){
            console.log(e)
            let el_link = this.querySelector('a[data-bs-toggle]');

            if(el_link != null){
              let nextEl = el_link.nextElementSibling;
              el_link.classList.add('show');
              nextEl.classList.add('show');
            }

          });
          everyitem.addEventListener('mouseleave', function(e){
            let el_link = this.querySelector('a[data-bs-toggle]');
            console.log(e)
            if(el_link != null){
              let nextEl = el_link.nextElementSibling;
              el_link.classList.remove('show');
              nextEl.classList.remove('show');
            }
          })
        });

      }
// end if innerWidth
    });
  },
  data() {
    return {
      scrolled: false
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    }
  }
}
</script>

<style scoped>
.fixed_header{
  position:fixed;
  background: #fff;
}
header.header.fixed_header ul.navbar-nav {
  border: none;
}
</style>


<template>

  <div class="service_wrap contat_wrapper pb-0" style="background:transparent;" data-aos="fade-right" id="contact-us">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>CONTACT US</span>
          <h2>Supercharge Your Business with State-of-the-Art Services</h2>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-bottom: 60px; border-bottom: 1px solid #EBEBEB; float: left; width: 100%;">
    <div class="service_wrap contat_wrapper plan  bg_half" data-aos="fade-right">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="contact_form">
              <form id="contact-form" name="contact-form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form_field">
                      <input type="text" id="name" name="name" class="form-control" placeholder="Name">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form_field">
                      <input type="text" id="email" name="email" class="form-control" placeholder="Email">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form_field">
                      <vue-tel-input style="height: 55px;font-size: 14px;background: #fff;margin-bottom:20px" id="phone" type="tel" v-model="phone" ></vue-tel-input>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form_field">
                      <select class="form-control">
                        <option>What are looking for?</option>
                        <option>Our Services</option>
                        <option>Our Technologies</option>
                        <option>Our Solutions</option>
                        <option>Others</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="md-form">
                      <textarea type="text" id="message" name="message" rows="3" class="form-control"
                                placeholder="Message"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                  <!-- <input class="btn btn_red hvr-sweep-to-right gray" type="submit" value="Take The First Step"> -->
                  <button class="btn btn_red hvr-sweep-to-right gray" type="submit">Take The First Step</button>

                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6">
            <div class="right_info ">
              <div class="row">
                <ul class="nav nav-tabs justify-content-start" id="myTab" role="tablist">
                  <li v-for="(country,index) in countries" v-bind:key="country.name" class="nav-item" :class="index == 0 ? 'active': ''" role="presentation">
                    <button class="nav-link" :class="index == 0 ? 'active': ''" :id="stringToSlug(country.name)+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+stringToSlug(country.name)+'-section'"
                            type="button" role="tab" aria-controls="Turkey" aria-selected="false">{{ country.name }}
                    </button>
                  </li>

                </ul>
                <div class="tab-content" id="myTabContent">
                  <div v-for="(country,index) in countries" v-bind:key="stringToSlug(country.name)" class="tab-pane fade" :class="index == 0 ? ' show active': ''" :id="stringToSlug(country.name)+'-section'" role="tabpanel" aria-labelledby="Mobile-tab">
                    <div class="row">
                      <div v-for="city in country.cities" v-bind:key="city.name" class="col-md-6">
                        <div class="loop_address flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <div class="icone">
                                <button type="button" data-bs-toggle="modal" :data-bs-target="'#'+stringToSlug(city.name)+'_modal'">
                                  <i class="fa-solid fa-location-dot"></i>
                                </button>

                              </div>
                              <div class="info_wid">
                                <div class="loops">
                                  <h2>{{ city.data.title }}</h2>
                                  <p>{{ city.data.heading }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="flip-card-back">
                              <div class="icone">
                                <button type="button" data-bs-toggle="modal" :data-bs-target="'#'+stringToSlug(city.name)+'_modal'">
                                  <i class="fa-solid fa-location-dot"></i>
                                </button>
                              </div>
                              <div class="info_wid">
                                <div class="loops">
                                  <h2>{{ city.data.title }}</h2>
                                  <p>{{ city.data.heading }}</p>
                                </div>
                                <div class="loops">
                                  <h2>Contact Number</h2>
                                  <p>Tel: {{ city.data.contact.tel }}
                                    <br>{{ city.data.contact.fax != undefined ? 'Fax: '+city.data.contact.fax : '' }}</p>
                                </div>
                                <div class="loops">
                                  <h2>Address</h2>
                                  <p>{{ city.data.address }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-for="(country,index) in countries" v-bind:key="'modal-main-'+index">
    <div v-for="(city,indx) in country.cities" class="modal fade" v-bind:key="'modal-'+indx" :id="stringToSlug(city.name)+'_modal'" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Address</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe :src="city.data.map" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>
      </div>
    </div>
  </template>


</template>

<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
  name: "ContactUsComponent",
  components:{
    VueTelInput
  },
  data() {
    return {
      phone:'',
      countries: [
        {
          name:'Pakistan',
          cities:[
            {
              name:"Karachi",
              data:{
                title:"Karachi, Pakistan",
                heading: "HEAD OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.5548014598303!2d67.02149757444504!3d24.844894145882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33dde40ccf1bb%3A0x8e8d04175cb2c7a5!2sBeaumont%20Plaza%2C%20Beaumont%20Rd%2C%20Civil%20Lines%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1689577465156!5m2!1sen!2s",
                contact:{
                  tel:"+ 92 21 3565 4981 – 84",
                  fax:"+92 21 3567 6514"
                },
                address:"14-16 Mezzanine Floor, Beaumont Plaza, Beaumont Road, Civil Lines, Karachi-75530 Pakistan"
              }
            },

            {
              name:"Lahore",
              data:{
                title:"Lahore, Pakistan",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d91131.99123588356!2d74.350145!3d31.510283999999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190451b287e55b%3A0xe5806e2f6be2702!2sLiberty%20Gate%20Plaza!5e1!3m2!1sen!2sus!4v1689577525729!5m2!1sen!2sus",
                contact:{
                  tel:"+ 92 42 3587 4001",
                  fax:"+ 92 42 3577 1571-3"
                },
                address:"Office # 01, 6th Floor, 10-C, Liberty Gate Plaza, M.M. Alam Road, Gulberg III, Lahore – 54660 Pakistan"
              }
            },

            {
              name:"Islamabad",
              data:{
                title:"Islamabad, Pakistan",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2778.754257497384!2d73.03384917495312!3d33.71030627668979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbe4d44d46005%3A0x41bb343cf451e3dd!2sJohar%20Rd%2C%20F%208%20Markaz%20F-8%2C%20Islamabad%2C%20Islamabad%20Capital%20Territory%2C%20Pakistan!5e1!3m2!1sen!2sus!4v1689577632630!5m2!1sen!2sus",
                contact:{
                  tel:"+92 51 285 5211 – 4",
                  fax:"+92 51 285 5216"
                },
                address:"14-Y, F-8 Markaz, Johar Road, Islamabad-44000 Pakistan"
              }
            }

          ]
        },
        {
          name:'UK',
          cities:[
            {
              name:"United Kingdom",
              data:{
                title:"United Kingdom",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1223.4635590413502!2d-1.8564614918167774!3d52.172018543700055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870db543b4bf1ad%3A0x7e362329af2fff1f!2s19%20Ash%20Pl%2C%20Bidford-on-Avon%2C%20Warwickshire%20B50%204GB%2C%20UK!5e0!3m2!1sen!2s!4v1689692630162!5m2!1sen!2s",
                contact:{
                  tel:"+44-75467-41915",
                },
                address:"19 Ash Place Bidford-on-Avon Warwickshire, B50 4GB"
              }
            }
          ]
        },

        {
          name:'UAE',
          cities:[
            {
              name:"Dubai",
              data:{
                title:"Dubai, UAE",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d462561.65915368934!2d55.227488!3d25.076022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sus!4v1689577714542!5m2!1sen!2sus",
                contact:{
                  tel:"+ 971 42276292",
                  fax:"+ 971 42272449"
                },
                address:"Verity Technolgoies JLT, Office # 403, Fortune Executive Tower, JLT,Dubai,U.A.E. "
              }
            }




          ]
        },
        {
          name:'Turkey',
          cities:[
            {
              name:"Istanbul, Turkey",
              data:{
                title:"Istanbul, Turkey",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2537.8180152282134!2d29.29226766164552!3d40.850116120873395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadc42415a1fad%3A0xadbaf1a2c91abbd6!2zw5Z6ZWsgxLDFnyBNZXIu!5e0!3m2!1sen!2s!4v1689692468433!5m2!1sen!2s",
                contact:{
                  tel:"+902163923135",
                  fax:"+905494622506"
                },
                address:"Aydıntepe Mah. Sahil Blv. Ozek Is Merk. E Blok No: 146/3 /Tuzla/ İstanbul"
              }
            }
          ]
        },

        {
          name:'Uzbekistan',
          cities:[
            {
              name:"Tashkent",
              data:{
                title:"Tashkent, Uzbekistan",
                heading: "OFFICE",
                map:"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d343.5526569672734!2d69.26068951850499!3d41.305069834641614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2s!4v1690445828261!5m2!1sen!2s",
                contact:{
                  tel:"+ 998 71 215 64 06 – 08",
                  fax:"+998 71 120 64 85"
                },
                address:"ABM Worldwide Technologies Ltd. 29/31 Rakatboshi Street, Tashkent, 700031, Republic of Uzbekistan"
              }
            }
          ]
        },






      ]
    }
  },
  methods: {

    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    },

    getHome() {
      /*this.axios.get(this.apiURL + '/getOffice').then((response) => {
        this.countries = response.data.countries;        
      });*/
    }
  },
  
  beforeMount() {

},
mounted() {

  this.getHome();
}
}

</script>

<style scoped>
.vue-tel-input{
  border: none !important;
}
</style>
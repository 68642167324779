<template>

  <div class="service_wrap contat_wrapper" data-aos="fade-left" id="contact-us">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>CONTACT US</span>
          <h2>Supercharge Your Business with State-of-the-Art Services</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="contact_form">
            <form id="contact-form" name="contact-form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text" id="name" name="name" class="form-control" placeholder="Name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form_field">
                    <input type="text" id="email" name="email" class="form-control" placeholder="Email">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form_field">
                    <vue-tel-input style="height: 55px;font-size: 14px;background: #fff" id="phone" type="tel" v-model="phone" mode="international"></vue-tel-input>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form_field">
                    <select class="form-control">
                      <option>What are looking for?</option>
                      <option>Our Services</option>
                      <option>Our Technologies</option>
                      <option>Our Solutions</option>
                      <option>Others</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form">
                    <textarea type="text" id="message" name="message" rows="3" class="form-control"
                              placeholder="Message"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-center">
                <a href="javascript:void(0)" class="btn btn_red hvr-sweep-to-right gray">Take The First Step</a>
              </div>
            </form>
          </div>
        </div>
        <div  class="col-md-6">
          <div class="right_info">

            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/mail.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Email</h2>
                <p>{{ configData.contact_email }}</p>
              </div>
            </div>

            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/call.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Contact Number</h2>
                <p>{{ configData.phone_1 }}</p>
              </div>
            </div>
            <div class="loop d-flex">
              <div class="img">
                <img src="/assets/media/images/address.svg" alt=""/>
              </div>
              <div class="content">
                <h2>Address</h2>
                <p>{{configData.address}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
export default {
  name: "ContactUsHome",
  components: {
    VueTelInput,
  },
  data() {
    return {
      configData: {},
      phone:''
    }
  },
  methods: {
    getHome() {
      this.axios.get(this.apiURL + '/getConfigData').then((response) => {
        this.configData = response.data.config;

        console.log(this.configData);
      });
    }
  },
  beforeMount() {

  },
  mounted() {

    this.getHome();
  }
}
</script>

<style scoped>
.vue-tel-input{
  border: none !important;
}
</style>
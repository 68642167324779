<template>
  <app-header :menuItem="menuBar"/>
  <router-view />
  <app-footer :menuItem="menuBar"/>
</template>

<script>

import AppHeader from "@/components/HeaderComponent";
import AppFooter from "@/components/FooterComponent";
import AOS from "aos";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  metaInfo: {
    title: 'LUCERNA-TECH',
        meta: [
      { name: 'description', content: 'My page description' },
      { name: 'keywords', content: 'vue, meta, example' }
    ],
        link: [
      { rel: 'icon', type: 'image/png', href: '/favicon.png' }
    ]
  },
  data() {
    return {
      menuBar: [],
    }
  },
  methods: {
    getMenu() {
      this.axios.get(this.apiURL + '/get-menu').then((response) => {
        this.menuBar = response.data;
      });
    }
  },
  beforeMount() {
    this.getMenu();
  },
  mounted() {
    AOS.init();
    
  }

}
</script>

<style>

</style>

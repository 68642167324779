<template>
  <div class="service_wrap plan client_wrap logo_hide_tb" style="background:transparent;" data-aos="fade-right">
    <div class="container">
      <div class="row">
        <div class="title">
          <span>{{ toShow == 1 ?'Client' : '' }}</span>
          <h2>{{ 'Our '+ (slug == 1 ? "Local" : "International") +' Clients' }}</h2>
        </div>
      </div>
      <div class="row" v-if="slug == 1">
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
          <li v-for="(cat,index) in item.categories" v-bind:key="cat.id" class="nav-item" role="presentation">
            <button class="nav-link" :class="index == 0 ? 'active': ''" :id="cat.title+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+stringToSlug(cat.title+'-content')" v-on:click="changeTab($event,'#'+stringToSlug(cat.title+'-content'))" type="button" role="tab" aria-controls="Mobile" aria-selected="true">
              {{ cat.title }}</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div v-for="(cat,index) in item.categories" v-bind:key="cat.id"  class="tab-pane fade show " :class="index == 0 ? 'active': ''" :id="stringToSlug(cat.title+'-content')" role="tabpanel" aria-labelledby="Mobile-tab">
            <div class="row">
                <template v-for="(tech) in item.local" v-bind:key="tech.id" >
                  <div v-if="tech.sub_category_id == cat.id" class="col-25">
                    <div class="loop_logo ">
                      <img :src="tech.image_path" alt="" />
                      <h2 style="min-height: 40px">{{ tech.title }}</h2>
                    </div>
                  </div>
                </template>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="slug == 0">
          <template v-for="(tech) in item.international" v-bind:key="tech.id">
            <div class="col-25">
              <div class="loop_logo ms-4 me-4" >
                <img :src="tech.image_path" alt="" />
                <h2 style="min-height: 40px">{{ tech.title }}</h2>
              </div>
            </div>
          </template>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "PortfolioComponent",
  props: ['slug','toShow'],
  data() {
    return {
      item: {},
      breakpoints: {
        // 700px and up
        100: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        768: {
          itemsToShow: 4,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    }
  },
  methods: {

    stringToSlug(str) {
      str = str.toLowerCase(); // Convert the string to lowercase
      str = str.replace(/[^a-z0-9]/g, '-'); // Replace non-alphanumeric characters with a hyphen
      str = str.replace(/-+/g, '-'); // Replace multiple consecutive hyphens with a single hyphen
      str = str.trim('-'); // Trim any leading or trailing hyphens
      return str;
    },
    getPortfolio() {
      this.axios.get(this.apiURL + '/getPortfolioCat').then((response) => {
        this.item = response.data.data;
      });
      console.log(this.item);
    }
  },
  mounted() {
    this.getPortfolio();
  }
}
</script>

<style scoped>
.col-25 {
  width: 20%;
}
.service_wrap.plan .col-25 {
  padding-right: 0;
}
.service_wrap.plan .col-25:nth-child(5n+5){ padding-right:calc(var(--bs-gutter-x) * .5); }
</style>